// Stylesheet import
import "./src/css/style.scss";
import "swiper/css/swiper.css";
import React from "react";
import { Provider } from "react-redux";
import store from "./src/store";
import SnackbarProvider from "react-simple-snackbar";

export const wrapRootElement = ({ element, location }) => {
  return (
    <Provider store={store}>
      <SnackbarProvider>{element}</SnackbarProvider>
    </Provider>
  );
};
