import {
  bakeLocalStorage,
  deleteLocalStorage,
  readLocalStorage,
} from "@helpers/storage";

export default (state = readLocalStorage("form"), action) => {
  // Check to see what type of action is being fired
  switch (action.type) {
    case "FORM_SET":
      bakeLocalStorage("form", action.payload);
      return action.payload;
    case "FORM_RESET":
      deleteLocalStorage("form");
      return null;
    default:
      // Return state by default if nothing else is met
      return state;
  }
};
